import { useStaticQuery, Link } from "gatsby"
import React from "react"
import Logo from "../assets/SVG/fin-logo-light.svg"

const Nav = () => {

const data = useStaticQuery(graphql`
    {
    strapiMainMenu {
      Menu {
        URL
        Label
        id
      }
      CTA {
        URL
        Label
      }
    }
  }
`)


return (
    <div>

        <div className={" navigationBar"}>
        <div data-uk-sticky="animation: uk-animation-slide-top; sel-target: .uk-navbar-container; cls-active: uk-navbar-sticky; cls-inactive: uk-navbar-transparent; top:100">
        <nav className="nav-options uk-navbar-container uk-position-top uk-navbar-transparent" data-uk-navbar>
            <div className="uk-navbar-left" >
            <ul className="uk-navbar-nav">
                <li>
                <Link className="logoLight" to="/"><img src={Logo} alt="The insurers logo light" width="200"/></Link>
                {/* <Link className="logoDark" to="/"><img src={LogoDark} alt="The insurers logo dark" width="200"/></Link> */}
                
                
                </li>
            </ul>
            </div>

            <div className="uk-navbar-right uk-visible@m">
                <ul className="uk-navbar-nav">
                    
                    
                    {data.strapiMainMenu.Menu.map((item, i) => {
                        
                            return (
                            <li key={item.id} >
                                <Link to={`/${item.URL}`} >
                                {item.Label} 
                                </Link>
                            </li>
                            )
                        
                        
                        })
                    }
                    
                    
                </ul>

                <a className="nav-cta cta-primary-btn accent" href={"/"+data.strapiMainMenu.CTA.URL}>{data.strapiMainMenu.CTA.Label}</a>
                
            </div>


            <div className="uk-navbar-right uk-hidden@m">
                <a href="#offcanvas-usage" className="uk-navbar-toggle menuMobileBtn" data-uk-toggle="target: #offcanvas-menu">
                    <span className="uk-margin-small-right">Menu</span>
                    <span data-uk-navbar-toggle-icon>&nbsp;</span>
                </a>
            </div>

            <div id="offcanvas-menu" data-uk-offcanvas="overlay: true; flip: true; mode: reveal">
                <div className="uk-offcanvas-bar uk-flex uk-flex-column">

                    <button className="uk-offcanvas-close" type="button" data-uk-close aria-label="close button"></button>

                    <ul className="uk-nav uk-nav-primary uk-nav-center uk-margin-auto-vertical">
                    <li><Link to="/">Home</Link></li>
                    {data.strapiMainMenu.Menu.map((item, i) => {
                    return (
                        <li key={item.id}>
                        <Link to={`/${item.URL}`}>
                            {item.Label}
                        </Link>
                        </li>
                    )
                    })
                }
                
                    </ul>
                </div>
            </div>


            


        </nav>
        </div>
        </div>


    </div>
)}

export default Nav