// import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import HeaderImg from "../assets/img/header-landing-img.png"
import HeaderImgFeatures from "../assets/img/features-header.png"


const Header = ({ siteTitle, type }) => {

  switch (type) {
    case "prices":

      return(
        <header>

          <div className="header-container type-prices">
            <div className="uk-panel uk-text-center">
              
              <h1><span>PRICING</span></h1>
              <p>Dolore deserunt ex proident est ea non eu dolor voluptate sint sunt cupidatat occaecat. Irure culpa proident mollit velit sunt mollit proident eiusmod adipisicing. Quis non fugiat id quis magna aliquip laborum ullamco minim.</p>

              <div className="header-prices-container">
                { /* four cards */}
                <div className="header-prices-card">
                  <h2>Free</h2>
                  <div className="header-prices-card-separator"></div>
                  <div className="header-prices-card-cost"><span>0</span>/month</div>
                  <ul className="header-prices-card-benefits">
                    <li>1 user</li>
                    <li>10 proposals / month</li>
                    <li>CRM integration</li>
                    <li>Payment integration</li>
                  </ul>
                  <a href="#0" className="primary-btn dark">Start using</a>
                </div>

                <div className="header-prices-card">
                  <h2>Standard</h2>
                  <div className="header-prices-card-separator"></div>
                  <div className="header-prices-card-cost"><span>4.99</span>/month</div>
                  <ul className="header-prices-card-benefits">
                    <li>1 user</li>
                    <li>10 proposals / month</li>
                    <li>CRM integration</li>
                    <li>Payment integration</li>
                  </ul>
                  <a href="#0" className="primary-btn dark">Start using</a>
                </div>

                <div className="header-prices-card">
                  <h2>Pro</h2>
                  <div className="header-prices-card-separator"></div>
                  <div className="header-prices-card-cost"><span>4.99</span>/month</div>
                  <ul className="header-prices-card-benefits">
                    <li>1 user</li>
                    <li>10 proposals / month</li>
                    <li>CRM integration</li>
                    <li>Payment integration</li>
                  </ul>
                  <a href="#0" className="primary-btn dark">Start using</a>
                </div>

                <div className="header-prices-card enterprise-card">
                  <h2>Agency/Enterprise</h2>
                  <div className="header-prices-card-separator"></div>
                  <div className="header-prices-card-cost"><span></span>Flexible pricing</div>
                  <ul className="header-prices-card-benefits">
                    <li>1 user</li>
                    <li>10 proposals / month</li>
                    <li>CRM integration</li>
                    <li>Payment integration</li>
                  </ul>
                  <a href="#0" className="primary-btn">Contact us</a>
                </div>


              </div>
            
            </div>

          </div>
        </header>
      )
      
      // break;
    case "features":
      return(
        <header>

          <div className="header-container">
            <div className="uk-panel">
              
              <img className="header-img uk-align-center uk-align-right@m uk-margin-remove-adjacent" src={HeaderImgFeatures} alt="header-img"></img>
              <h1>Have <span>everything</span> you <span>need</span> when you <span>need</span> it</h1>
              <p>Dolore deserunt ex proident est ea non eu dolor voluptate sint sunt cupidatat occaecat. Irure culpa proident mollit velit sunt mollit proident eiusmod adipisicing. Quis non fugiat id quis magna aliquip laborum ullamco minim.</p>
            
              {/* <div className="header-cta-container">
                <a href="#0" className="cta-primary-btn">Free sign-up</a>
                <a href="#0" className="cta-secondary-btn">TOP FEATURES</a>
              </div> */}
              
            </div>

          </div>
        </header>
      )

      case "how-it-works":
      return(
        <header>

          <div className="header-container">
            <div className="uk-panel">
              
              <img className="header-img uk-align-center uk-align-right@m uk-margin-remove-adjacent" src={HeaderImgFeatures} alt="header-img"></img>
              <h1>How it all comes together</h1>
              <p>Dolore deserunt ex proident est ea non eu dolor voluptate sint sunt cupidatat occaecat. Irure culpa proident mollit velit sunt mollit proident eiusmod adipisicing. Quis non fugiat id quis magna aliquip laborum ullamco minim.</p>
            
              {/* <div className="header-cta-container">
                <a href="#0" className="cta-primary-btn">Free sign-up</a>
                <a href="#0" className="cta-secondary-btn">TOP FEATURES</a>
              </div> */}
              
            </div>

          </div>
        </header>
      )

      case "contact":
        return(
          <div></div>
        )
      
      case "about":
        return(
          <div></div>
        )

      case "privacy":
        return(
          <div></div>
        )

      case "roadmap":
        return(
          <div></div>
        )
  
    default:
      return(
        <header>

          <div className="header-container">
            <div className="uk-panel">
              
              <img className="header-img uk-align-center uk-align-right@m uk-margin-remove-adjacent" src={HeaderImg} alt="header-img"></img>
              <h1><span>Personalised</span> customer <span>experience</span> in a <span className="accent-color">fingersnap</span></h1>
              <p>Discover how fast you can create, send and personalise emails, quotes, proposals or your website. Automatically connect your other apps and start saving time.</p>
            
               <div className="header-cta-container">
                <a href="#0" className="cta-primary-btn accent">Free Forever - Coming soon!</a>
                {/* <a href="#0" className="cta-primary-btn outline">Top features</a> */}
              </div> 
              
            </div>

          </div>
        </header>
      )
      // break;
  }

  }

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
