import React from "react"
import { FaCircleNotch } from "react-icons/fa";
import {Link} from "gatsby";

const sendSignup = event =>{

    var cform = {
      email: document.getElementById("signup-email").value
    }
    console.log(cform);
    var submitBtn = document.getElementById("sign-up-submit");
    submitBtn.innerHTML = '<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" class="spinner" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M288 39.056v16.659c0 10.804 7.281 20.159 17.686 23.066C383.204 100.434 440 171.518 440 256c0 101.689-82.295 184-184 184-101.689 0-184-82.295-184-184 0-84.47 56.786-155.564 134.312-177.219C216.719 75.874 224 66.517 224 55.712V39.064c0-15.709-14.834-27.153-30.046-23.234C86.603 43.482 7.394 141.206 8.003 257.332c.72 137.052 111.477 246.956 248.531 246.667C393.255 503.711 504 392.788 504 256c0-115.633-79.14-212.779-186.211-240.236C302.678 11.889 288 23.456 288 39.056z"></path></svg>';
    submitBtn.classList.add("form-submitting");
  
    var body = {
      Token: 'phHRMocDrnRvQgKl0zcjMOhD8ZeaWrnn2local',
      FormData: JSON.stringify(cform)   
    };
    fetch("https://api.phaeria.com/api/home/SendEmailNotification", {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(response => 
      response.text().then(function (responseText) {
        console.log("response text: " + responseText);
        if(responseText.localeCompare('"Form sent successfully."') === 0){
          //success send message
          console.log("success"+ responseText);
          submitBtn.innerHTML = "Sent!";
          submitBtn.classList.remove("form-submitting");
        }else{
          //error sending message
          console.log("something went wrong");
          submitBtn.innerHTML = "Ups... Try again!";
          submitBtn.classList.remove("form-submitting");
        }
          
  
      })
    )
    // console.log("Message sent");
  
    event.preventDefault();
  }

const Footer = () => (
    <div>

        <div className="footer-section">
            <div data-uk-grid>
                <div className="uk-width-1-4@m uk-width-1-2@s uk-text-left">
                    {/* <ul>
                        <li><Link to={'/features'}>Features</Link></li>
                        <li><Link to={'/solutions'}>Solutions</Link></li>
                        <li><Link to={'/prices'}>Pricing</Link></li>
                        <li><Link to={'/about'}>About us</Link></li>
                        <li><Link to={'/marketplace'}>Marketplace</Link></li>
                    </ul> */}
                </div>
                <div className="uk-width-1-4@m uk-width-1-2@s uk-text-left">
                    <ul>
                        <li><a href="/privacy">Privacy Policy</a></li>
                        <li><a href="/terms-conditions">Terms &amp; Conditions</a></li>
                        {/* <li><a href="/roadmap">Roadmap</a></li> */}
                    </ul>
                </div>
                <div className="sign-up-container uk-width-auto@m uk-width-1-1@s uk-text-left">
                    Subscribe for more details
                    <div className="sign-up-form">
                        
                        <form id="sign-up-form" onSubmit={sendSignup}>
                            <input id='signup-email' type='text' placeholder='Enter your email here' className='input-line full-width' required></input>
                            <button id="sign-up-submit" className='' type="submit">Subscribe<FaCircleNotch className="spinner"/></button>
                        </form>
                        
                        
                    </div>
                </div>
            </div>
        </div>

        <footer>
          © {new Date().getFullYear()} Fingersnap Ltd -  All rights reserved. 
        </footer>
    </div>
)

export default Footer